<template>
  <div class="userInfo">
    <div class="userInfo-head">
      <div class="userInfo-head-list">
        <div class="userInfo-head-list-itemImg" v-if="childUserData.member && childUserData.member.head_picture">
          <img
            :src="childUserData.member.head_picture"
          />
        </div>
        <div class="userInfo-head-list-itemImg" v-else>
          <img
            src="../../assets/none.png"
          />
        </div>
        <div class="userInfo-head-list-itemId">ID:{{ childUserData.member && childUserData.member.id ? childUserData.member.id : '' }}</div>
      </div>
      <div class="userInfo-head-list">
        <div class="userInfo-head-list-item">
          <label>会员姓名：</label>
          <span>{{ childUserData.member && childUserData.member.name ? childUserData.member.name : '--' }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员性别：</label>
          <span>{{ childUserData.member && childUserData.member.sex - 0 ? "男" : "女" }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员号码：</label>
          <span>{{ childUserData.member && childUserData.member.phone ? childUserData.member.phone : "--" }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员生日：</label>
          <span>{{ childUserData.member && childUserData.member.birthday ? childUserData.member.birthday : "--" }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员标签：</label>
          <el-select v-model="memberLabels" multiple placeholder="请选择" @change="handleSetLabel">
            <el-option
              v-for="item in labels"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="userInfo-head-list">
        <div class="userInfo-head-list-item">
          <label>会员等级：</label>
          <span>{{
            childUserData.member && childUserData.member.membertype
              ? childUserData.member.membertype.name
              : "大众会员"
          }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员推荐人：</label>
          <span>--</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>会员余额：</label>
          <span>￥{{ childUserData.member && childUserData.member.amount ? childUserData.member.amount : "0.00" }}</span>
        </div>
        <div class="userInfo-head-list-item">
          <label>累计消费：</label>
          <span>￥{{ childUserData.member && childUserData.sale_amount ? childUserData.sale_amount : "0.00" }}</span>
        </div>
      </div>
      <div class="userInfo-head-list">
        <div class="userInfo-head-list-itemMax">
          <el-button size="medium" @click="dialogVisible4 = true"
            >充值</el-button
          >
          <el-button size="medium" @click="edit">编辑</el-button>
        </div>
      </div>
    </div>
    <div class="userInfo-cont">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in tabData"
          :key="index"
          :label="item.name"
          :name="item.name"
        ></el-tab-pane>
      </el-tabs>

      <div class="userInfo-cont-btn">
        <span v-if="tabDataIndex == 1"
          >累计消费：￥{{ childUserData.sale_amount || "0.00" }}</span
        >
        <el-button
          size="small"
          @click="showMemberfollow"
          type="primary"
          v-if="tabDataIndex == 2"
          >添加跟进</el-button
        >
      </div>

      <div class="userInfo-cont-table" v-if="tabDataIndex == 0">
        <el-table
          :data="childUserData.carData"
          style="width: 100%"
          :border="$table('border')"
          key="1"
          :header-cell-style="{ background: $table('bgcolor') }"
        >
          <el-table-column prop="name" label="卡项名称"> </el-table-column>
          <el-table-column prop="name" label="图片">
            <template slot-scope="scope">
              <div class="userInfo-cont-table-img">
                <img
                  :src="scope.row.card.picture"
                  :alt="scope.row.name"
                  v-if="scope.row.card && scope.row.card.picture"
                />
                <img src="../../assets/none.png" :alt="scope.row.name" v-else />
              </div>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <!-- <template slot-scope="scope">
              {{ scope.row.types - 0 ? "次卡" : " 套餐卡" }}
            </template> -->
            <template slot-scope="scope">
              {{ scope.row.types - 0 ? "套餐卡" : " 次卡" }}
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="卡售价"> </el-table-column>
          <el-table-column prop="created_at" label="开卡时间">
          </el-table-column>
          <el-table-column prop="exp" label="剩余有效期">
            <template slot-scope="scope">
              <div v-html="getTimes(scope.row.exp)">
                {{ getTimes(scope.row.exp) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="剩余次数">
            <template slot-scope="scope">
              {{ scope.row.balance_count || 0 }} 次
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button size="mini" @click="infoShow(scope.row)" v-if="scope.row.types - 0"
                >详情</el-button>
                <el-button size="mini">使用</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="userInfo-cont-table" v-if="tabDataIndex == 1">
        <el-table
          :data="tabData[tabDataIndex].data"
          style="width: 100%"
          :border="$table('border')"
          key="2"
          :header-cell-style="{ background: $table('bgcolor') }"
        >
          <el-table-column prop="name" label="类型">
            <template slot-scope="scope">
              {{ getOrderType(scope.row.type, 0) }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column prop="name" label="单价">
            <template slot-scope="scope">
              <span v-if="scope.row.goods_info.length">
                {{ scope.row.goods_info[0].price || "0.00" }}
              </span>
              <span v-else>
                {{ scope.row.goods_info.price || "0.00" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量"> </el-table-column>
          <el-table-column prop="order.sale_amount" label="实收金额">
          </el-table-column>
          <el-table-column prop="name" label="支付方式">
            <template slot-scope="scope">
              <span v-if="scope.row.payments.length">{{
                getOrderType(scope.row.payments[0].pay_type, 1)
              }}</span>
              <span v-else>其他</span>
            </template>
          </el-table-column>
          <el-table-column prop="order.order_time" label="购买时间">
          </el-table-column>
          <el-table-column prop="remark" label="备注">
            <template slot-scope="scope">
              <div v-if="scope.row.order.remark">
                <el-popover
                  placement="top-start"
                  title="订单备注"
                  width="160"
                  trigger="hover"
                  :content="scope.row.order.remark"
                >
                  <!-- <el-button slot="reference">hover 激活</el-button> -->
                  <i class="el-icon-s-comment" slot="reference"></i>
                </el-popover>
              </div>
              <span v-else style="color:#ccc;">无备注...</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button size="mini" @click="infoShow(scope.row)"
                  >详情</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="$pageStatus(tabData[tabDataIndex].total)[0]">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="$pageStatus(tabData[tabDataIndex].total)[1]"
            layout="total, prev, pager, next, jumper"
            :total="tabData[tabDataIndex].total"
          >
          </el-pagination>
        </div>
      </div>

      <div class="userInfo-cont-table" v-if="tabDataIndex == 2">
        <el-table
          :data="tabData[tabDataIndex].data"
          :border="$table('border')"
          key="3"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <el-table-column prop="id" label="跟进编号"> </el-table-column>
          <el-table-column prop="staff.name" label="员工名称">
          </el-table-column>
          <el-table-column prop="staff_id" label="员工ID"> </el-table-column>
          <el-table-column prop="created_at" label="创建时间">
          </el-table-column>
          <el-table-column prop="content" label="跟进内容"> </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title="修改资料"
      :visible.sync="dialogVisible"
      :append-to-body="dialogVisible"
      width="50%"
    >
      <div class="userIndex-edit">
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>姓名：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>性别：</span>
            <el-select
              v-model="formLabelAlign.sex"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>手机：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.phone"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>生日：</span>
            <el-date-picker
              style="width:100%"
              v-model="formLabelAlign.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>会员等级：</span>
            <el-select
              v-model="formLabelAlign.category_id"
              filterable
              style="width: 100%;"
              placeholder="请选择..."
            >
              <el-option
                v-for="item in options1"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>推荐人：</span>
            <el-select
              v-model="formLabelAlign.p_user_id"
              style="width: 100%;"
              filterable
              placeholder="请输入关键字..."
            >
              <el-option
                v-for="item in options2"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>客户来源：</span>
            <el-select
              v-model="formLabelAlign.source_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-select
              v-model="formLabelAlign.sexs"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>备注：</span>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="formLabelAlign.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateList">保存修改</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->

    <!-- 卡详情 -->
    <el-dialog
      title="查看卡项详情"
      :visible.sync="dialogVisible1"
      :append-to-body="dialogVisible1"
      width="1200px"
    >
      <div class="order-infos" v-if="form.name">
        <!-- 套餐卡 -->
        <div class="order-infos-cont" v-if="tabDataIndex == 0">
          <div class="order-infos-cont-item">
            <el-table
              :data="form.treats"
              :border="$table('border')"
              key="5"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column label="项目">
                <template slot-scope="scope">
                  <div>{{ scope.row.project && scope.row.project.name ? scope.row.project.name : '--' }}</div>
                </template>
              </el-table-column>
              <el-table-column label="图片">
                <template slot-scope="scope">
                  <div class="userInfo-cont-table-img" v-if="scope.row.project && scope.row.project.picture">
                    <img
                      :src="scope.row.project.picture"
                    />
                  </div>
                  <div class="userInfo-cont-table-img" v-else>
                    <img src="../../assets/none.png"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="有效期">
                <template slot-scope="scope">
                  <div v-if="scope.row.exp">
                    <div v-html="getTimes(scope.row.exp)"></div>
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column label="购买时间">
                <template>{{ form.created_at }}</template>
              </el-table-column>
              <el-table-column label="剩余次数">
                <template slot-scope="scope">
                  <p>总次数：{{ scope.row.total_count || 0 }} 次</p>
                  <p>剩余次数： {{ scope.row.balance_count || 0 }} 次</p>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="order-infos-cont" v-if="tabDataIndex == 1">
          <div class="order-infos-cont-item">
            <el-table
              :data="[form.goods_info]"
              :border="$table('border')"
              key="6"
              :header-cell-style="{ background: $table('bgcolor') }"
            >
              <el-table-column prop="name" label="名称"> </el-table-column>
              <el-table-column prop="name" label="图片">
                <template slot-scope="scope">
                  <div class="userInfo-cont-table-img">
                    <img
                      v-if="scope.row.picture"
                      :src="scope.row.picture"
                      :alt="form.name"
                    />
                    <img v-else src="../../assets/none.png" :alt="form.name" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量">
                <template>
                  {{ form.num }}
                </template>
              </el-table-column>
              <el-table-column prop="num" label="单价">
                <template>
                  {{ form.goods_info.price || "0.00" }}
                </template>
              </el-table-column>
              <el-table-column prop="num" label="实收">
                <template>
                  {{ form.order.sale_amount || "0.00" }}
                </template>
              </el-table-column>
              <el-table-column prop="disc" label="折扣">
                <template>{{ form.disc }}</template>
              </el-table-column>
              <el-table-column prop="amount" label="员工/提成">
                <template>
                  {{
                    form.staffs.length ? form.staffs[0].staff_info.name : "--"
                  }}/ ￥{{
                    form.staffs.length ? form.staffs[0].amount : "0.00"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <div class="order-infos-title" v-show="form.types == 0">套餐内容</div>
        <div class="order-infos-cont" v-show="form.types == 0">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
        </div> -->
        <!-- 套餐卡 -->

        <!-- 次卡 -->
        <!-- <div class="order-infos-title" v-show="form.types == 1">次卡内容</div>
        <div class="order-infos-cont" v-show="form.types == 1">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list"></div>
            <div class="order-infos-cont-item-list"></div>
          </div>
          <div class="order-infos-cont-item"></div>
        </div> -->
        <!-- 次卡 -->

        <div class="order-infos-title" v-if="tabDataIndex == 1">
          订单详情
        </div>
        <div class="order-infos-cont" v-if="tabDataIndex == 1">
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list" v-if="form.order">
              <span>订单编号：</span>
              <p>{{ form.order.code || "--" }}</p>
            </div>
            <!-- <div class="order-infos-cont-item-list">
              <span>服务门店：</span>
              <p>{{ form.shop ? form.shop.name : "--" }}</p>
            </div> -->
            <div class="order-infos-cont-item-list" v-if="form.order">
              <span>订单时间：</span>
              <p>{{ form.order.order_time || "--" }}</p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>备注信息：</span>
              <p :title="form.order.remark">
                {{ form.order.remark || "无备注..." }}
              </p>
            </div>
          </div>
          <div class="order-infos-cont-item">
            <div class="order-infos-cont-item-list">
              <span v-if="tabDataIndex == 0">充值金额：</span>
              <span v-if="tabDataIndex > 0">订单金额：</span>
              <p>￥{{ form.order.order_amount || "0" }}</p>
            </div>
            <div v-if="0">
              <div
                class="order-infos-cont-item-list"
                v-show="tabDataIndex == 0"
              >
                <span>赠送金额：</span>
                <p>￥0.00</p>
              </div>
              <div
                class="order-infos-cont-item-list"
                v-show="tabDataIndex >= 1 && tabDataIndex < 5"
              >
                <span>优惠金额：</span>
                <p>
                  ￥-{{ (form.price_o - 0 - (form.price_sale - 0)).toFixed(2) }}
                </p>
              </div>
            </div>
            <div class="order-infos-cont-item-list" v-show="tabDataIndex == 0">
              <span>合计充值：</span>
              <p>
                ￥{{
                  (
                    (form.order.order_amount - 0 || 0) +
                    (form.order.gift_amount - 0 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>支付方式：</span>
              <p>
                {{
                  form.payments.length
                    ? getOrderType(form.payments[0].pay_type, 1)
                    : "其他"
                }}
              </p>
            </div>
            <div class="order-infos-cont-item-list">
              <span>实收金额：</span>
              <p>￥{{ form.order.sale_amount || "0" }}</p>
            </div>
          </div>
        </div>

        <div class="order-infos-title" v-if="form.card">
          卡项简介
        </div>
        <div class="order-infos-cont" v-if="form.card">
          <div v-html="form.card.detail"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible1 = false"
          >关闭详情</el-button
        >
      </span>
    </el-dialog>
    <!-- 卡详情 -->

    <!-- 添加跟进 -->
    <el-dialog
      title="添加会员跟进内容"
      :visible.sync="dialogVisible2"
      :append-to-body="dialogVisible2"
      width="500px"
    >
      <div class="user-center">
        <span class="user-center-title">跟进员工：</span>
        <el-select
          v-model="memberfollow.staff_id"
          style="width: 100%;"
          filterable
          placeholder="请输入关键字..."
        >
          <el-option
            v-for="item in options2"
            :key="item.name"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="user-center">
        <span class="user-center-title">跟进内容：</span>
        <el-input
          type="textarea"
          style="width: 100%;"
          placeholder="请输入内容..."
          v-model="memberfollow.content"
          maxlength="300"
          show-word-limit
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="addMemberfollow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加跟进 -->

    <!-- 充值 -->
    <el-dialog
      title="会员充值"
      :visible.sync="dialogVisible4"
      :append-to-body="dialogVisible4"
      width="600px"
    >
      <pay :key="new Date().getTime()" :userId="childUserData.member" :work="work" />
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">收起充值界面</el-button>
      </span> -->
    </el-dialog>
    <!-- 充值 -->
  </div>
</template>

<script>
import pay from "../../components/pay/pay.vue";
export default {
  name: "userInfo",
  props: ["userData", "values", "userOrder", "userType", "work"],
  components: { pay },
  data() {
    return {
      childUserData: this.userData,
      formLabelAlign: {},
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible4: false,
      options: [
        {
          id: "0",
          name: "女",
        },
        {
          id: "1",
          name: "男",
        },
      ],
      options1: this.userType,
      options2: this.work,
      options3: [], // 客户来源列表
      tabData: [
        { id: 0, name: "会员卡项", data: [], page: 1, total: 1, url: "" },
        {
          id: 1,
          name: "交易记录",
          data: [],
          page: 1,
          total: 1,
          url: "/api/app/meiye/finance/bill_store",
        },
        {
          id: 2,
          name: "跟进记录",
          data: [],
          page: 1,
          total: 1,
          url: "/api/app/meiye/memberfollow/store",
        },
      ],
      tabDataIndex: 0, //下标
      activeName: "会员卡项",
      form: {},
      datas: {},
      orderType: [
        { type: 1, name: "充值" },
        { type: 2, name: "套餐卡" },
        { type: 3, name: "充次" },
        { type: 4, name: "商品" },
        { type: 5, name: "项目" },
      ],
      payType: [
        { type: 1, name: "现金" },
        { type: 2, name: "微信" },
        { type: 3, name: "支付宝" },
        { type: 4, name: "银行卡" },
        { type: 5, name: "储值卡(余额)" },
        { type: 7, name: "核销" },
        { type: 11, name: '美团' },
      ],
      memberfollow: {}, //跟进记录
      labels: [], // 会员标签列表（全部）
      memberLabels: [], // 会员标签列表（选中的）
      debounceTimer: null, // 防抖定时器
    };
  },
  methods: {
    /**
     * 获取会员标签列表
     */
    getMemberLabels() {
      this.$axios({
        href: "/api/app/meiye/memberlabel/store",
      }).then((res) => {
        console.log('getMemberLabels', res);
        if (res.data.successful === 1) {
          this.labels = res.data.data;
          if (this.childUserData.member && this.childUserData.member.labels && this.childUserData.member.labels.length) {
            this.memberLabels = [];
            this.childUserData.member.labels.forEach(item => {
              this.memberLabels.push(item.label_id);
            })
          }
        }
      });
    },
    /**
     * 设置会员标签
     */
    handleSetLabel() {
      const that = this;
      clearTimeout(that.debounceTimer);
      that.debounceTimer = setTimeout(function () {
        console.log('memberLabels', that.memberLabels);
        that.$axios({
          href: "/api/app/meiye/member/setlabel",
          data: {
            member_id: that.childUserData.member.id,
            labels: JSON.stringify(that.memberLabels)
          }
        }).then((res) => {
          console.log('setLabel', res);
          if (res.data) {
            if (res.data.successful === 1) {
              that.$message({
                type: "success",
                message: "修改成功!",
              });
            } else {
              that.$message({
                type: "error",
                message: res.data.message,
              });
            }
          }
        });
      }, 1000);
    },
    /**
     * 获取客户来源列表
     */
    getCustomerSourceList() {
      this.$axios({
        href: "/api/app/meiye/membersource/store",
        loading: true,
      }).then((res) => {
        console.log('getCustomerSouceListRes', res);
        if (res.data.successful === 1) {
          this.options3 = res.data.data;
        }
      });
    },
    edit() {
      this.formLabelAlign = JSON.parse(JSON.stringify(this.childUserData.member));
      console.log('childUserData', this.childUserData);
      this.dialogVisible = true;
    },
    updateList() {
      let d = this.formLabelAlign;
      if (!d.name) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
        });
        return false;
      }

      if (!d.sex) {
        this.$message({
          message: "请选择会员性别",
          type: "warning",
        });
        return false;
      }

      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
          d.phone
        )
      ) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return false;
      }

      if (!d.category_id) {
        this.$message({
          message: "请选择会员类型",
          type: "warning",
        });
        return false;
      }
      this.$axios({
        href: "/api/app/meiye/member/edit",
        data: d,
      }).then(() => {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.getInfo(d);
      });
    },
    /* 获取用户信息 */
    getInfo(obj) {
      this.$axios({
        href: "/api/app/meiye/member/index",
        data: { id: obj.id },
      }).then((res) => {
        this.childUserData = res.data;
        this.formLabelAlign.source_id = res.data.source_id;
        this.dialogVisible = false;
      });
    },
    /* tab切换 */
    handleClick(tab) {
      this.tabDataIndex = tab.index;
      this.datas = {
        member_id: this.childUserData.member.id,
        page: this.tabData[this.tabDataIndex].page
      };
      this.form = {};
      this.getUserLIst();
    },
    /* 获取过期时间 */
    getTimes(obj) {
      if (!obj || obj == "0000-00-00") {
        return "永久";
      }
      let d = new Date().getTime();
      let t = new Date(obj).getTime();
      let s = Math.floor((d - t) / 86400000);
      let num = Math.ceil((t - d) / 86400000);
      if (t < d) {
        if (s > 1) {
          return `<span style="color:red">${obj}(已过期${s}天)</span>`;
        }
        if (s == 0) {
          return `<span style="color:#8088a8">${obj}(马上到期)</span>`;
        }
      }

      if (num == 0) {
        return `<span style="color:#8088a8">${obj}(马上到期)</span>`;
      }
      if (num < 0) {
        return `<span style="color:red">${obj}(已过期${num * -1}天)</span>`;
      }

      return obj + "，剩余：" + num + "天";
    },
    /* 显示详情 */
    infoShow(obj) {
      obj.cards = obj.card ? [obj.card] : obj.goods_info;
      this.form = obj;
      this.dialogVisible1 = true;
      if (obj.types) {
        obj.card = {
          picture: "",
          balance_count: obj.balance_count,
          total_count: obj.total_count,
        };
      }
      console.log('obj', obj);
    },
    handleSizeChange(val) {
      this.tabData[this.tabDataIndex].page = val;
      this.datas.page = val;
      this.getUserLIst();
    },
    handleCurrentChange(val) {
      this.tabData[this.tabDataIndex].page = val;
      this.datas.page = val;
      this.getUserLIst();
    },
    /* 获取用户详细信息 */
    getUserLIst() {
      let href = this.tabData[this.tabDataIndex].url;
      if (!href) {
        return false;
      }

      this.$axios({
        href: href,
        data: this.datas,
      }).then((res) => {
        console.log('getUserLIstRes', res);
        /* 订单记录 */
        if (this.tabDataIndex == 1) {
          this.tabData[this.tabDataIndex].data = res.data.data.items;
          this.tabData[this.tabDataIndex].total = res.data.data.total;
        }
        /* 跟进记录 */
        if (this.tabDataIndex > 1) {
          this.tabData[this.tabDataIndex].data = res.data;
        }
      });
    },
    /* 获取订单类型 payType*/
    getOrderType(obj, type) {
      let len = type ? this.payType.length : this.orderType.length;
      let d = type ? this.payType : this.orderType;
      // console.log(obj);
      for (let i = 0; i < len; i++) {
        if (obj - 0 == d[i].type) {
          return d[i].name;
        }
        if (i == len - 1) {
          return "其他";
        }
      }
    },
    /* 添加跟进 */
    showMemberfollow() {
      this.dialogVisible2 = true;
      this.memberfollow = {};
    },
    /* 添加会员跟进 */
    addMemberfollow() {
      let d = this.memberfollow;
      d.member_id = this.childUserData.member.id;
      if (!d.content) {
        this.$message.error("请输入跟进内容");
        return false;
      }

      this.$axios({
        href: "/api/app/meiye/memberfollow/add",
        data: d,
      }).then(() => {
        this.$message("添加会员跟进成功！");
        this.getUserLIst();
        this.dialogVisible2 = false;
      });
    },
  },
  created() {
    this.getMemberLabels();
    this.getCustomerSourceList();
  },
};
</script>

<style lang="scss" scoped>
.userInfo {
  &-head {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    // height: 245px;
    background: #8088a8;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    &-list {
      width: 25%;
      height: 100%;
      &-itemImg {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      &-itemId {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 30%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      &-itemMax {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      &-item {
        padding: 0 10px;
        // background: red;
        box-sizing: border-box;
        width: 100%;
        // height: 35px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        label {
          display: inline-block;
          width: 100px;
          height: 35px;
          line-height: 35px;
          font-size: 14px;
          font-family: Microsoft YaHei, Microsoft YaHei-Normal;
          font-weight: Normal;
          text-align: left;
          color: #dddddd;
        }
        span {
          display: inline-block;
          width: 150px;
          height: 35px;
          line-height: 35px;
          font-family: Microsoft YaHei, Microsoft YaHei-Normal;
          font-weight: Normal;
          text-align: left;
          color: #ffffff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .el-select {
          width: 190px;
        }
      }
      &-item:first-child {
        margin-top: 20px;
      }
    }
  }
  &-cont {
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    &-btn {
      width: 260px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      right: 10px;
      // background: red;
    }
    &-table {
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      height: 550px;
      overflow-y: auto;
      &-img {
        width: 50px;
        height: 50px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
        }
      }
    }
  }
}

.order-infos {
  width: 100%;
  height: auto;
  &-title {
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: Bold;
    text-align: left;
    color: #000000;
    line-height: 60px;
  }
  &-cont {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: auto;
    display: flex;
    &-item {
      width: 100%;
      height: auto;
      &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 5px;
          width: 50px;
          height: 50px;
          border-radius: 5px;
        }
      }
      &-imgs {
        // margin-right: 5px;
        width: 100%;
        height: 20px;
        margin-bottom: 2px;
        // background: red;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // border-radius: 2px;
        img {
          margin-right: 5px;
          width: 20px;
          height: 20px;
          border-radius: 2px;
        }
        p {
          max-width: 80px;
          height: 20px;
          margin-right: 2px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        // span{

        // }
      }
      &-list {
        width: 90%;
        height: 25px;
        margin-bottom: 10px;
        display: flex;
        span {
          display: inline-block;
          width: 100px;
          height: 100%;
          line-height: 25px;
          text-align: left;
          color: #888;
          // background: chartreuse;
        }
        p {
          padding: 0;
          color: #000;
          margin: 0;
          width: 65%;
          height: 25px;
          line-height: 25px;
          // background: red;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
}

.userIndex-edit {
  &-list {
    &-item {
      display: flex;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 120px;
        text-align: left;
      }
    }
  }
}
</style>

<style>
.userInfo-cont .el-tabs__nav-wrap::after {
  background-color: rgba(255, 255, 255, 0);
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.user-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.user-center-title {
  display: inline-block;
  width: 120px;
}
</style>
