<template>
  <div class="userIndex">
    <div class="userIndex-right">
      <div class="userIndex-right-cont">
        <div class="userIndex-right-cont-head">
          <div class="userIndex-right-cont-head-list" v-if="0">
            <span style="width:130px;">选择门店：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="userIndex-right-cont-head-list" v-if="0">
            <span>选择分类：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="userIndex-right-cont-head-list" style="width:400px;">
            <!-- <span style="width:120px;">搜索：</span> -->
            <!-- <el-input
              placeholder="请输入搜索关键字"
              v-model="input"
              clearable
              @clear="clears"
              @input="inputs"
              class="input"
              style="width:100%"
            >
            </el-input> -->
            <el-input
              placeholder="请输入搜索关键字"
              v-model="input"
              clearable
              @clear="clears"
              class="input"
              style="width:100%"
            >
            </el-input>
          </div>
          <div class="userIndex-right-cont-head-list" style="margin-left:10px">
            <el-button type="primary" icon="el-icon-search" @click="searchs"
              >查询</el-button
            >
          </div>
          <div class="userIndex-right-cont-head-list">
            <el-button type="danger" plain @click="dialogFormVisible = true">
              流失等级设置
            </el-button>
            <el-button type="danger" plain icon="el-icon-plus" @click="edit(1)">
              添加会员
            </el-button>
          </div>
        </div>
        <el-table
          :data="userSearchData"
          :border="$table('border')"
          :stripe="$table('stripe')"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <el-table-column prop="id" label="序号" :width="$table('width')">
            <template slot-scope="scope">
              <span v-if="currentPage4 < 2">
                {{ scope.$index + 1 }}
              </span>
              <span v-else>
                {{ (scope.$index + 1) + currentPage4 * 15 -15}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="head_picture" label="头像">
            <template slot-scope="scope">
              <div class="userIndex-photo">
                <img
                  :src="scope.row.head_picture"
                  :alt="scope.row.name"
                  v-if="scope.row.head_picture"
                />
                <img src="../../assets/none.png" :alt="scope.row.name" v-else />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="会员名称"></el-table-column>
          <el-table-column prop="sex" label="性别">
            <template slot-scope="scope">
              {{ scope.row.sex - 0 ? "男" : "女" }}
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="card" label="会员卡号"> </el-table-column>
          <el-table-column prop="member_type" label="会员等级">
            <template slot-scope="scope">
              {{
                scope.row.membertype ? scope.row.membertype.name : "大众会员"
              }}
            </template>
          </el-table-column>
          <el-table-column label="来源">
            <template slot-scope="scope">
              <template v-if="scope.row.source">
                <div>{{scope.row.source.name}}</div>
              </template>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="标签">
            <template slot-scope="scope">
              <template v-if="scope.row.labels.length">
                <span v-for="item in scope.row.labels" :key="item.label_id">#{{item.name}} </span>
              </template>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="流失等级">
            <template slot-scope="scope">
              <div v-if="scope.row.ls && scope.row.ls == 1" style="color: #409EFF">活跃用户</div>
              <div v-else-if="scope.row.ls && scope.row.ls == 2">轻度流失</div>
              <div v-else-if="scope.row.ls && scope.row.ls == 3" style="color: #F56C6C">重度流失</div>
              <div v-else-if="scope.row.ls && scope.row.ls == 4" style="color: #E6A23C">已流失</div>
              <div v-else></div>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="会员资产" v-if="0">
            <template slot-scope="scope">
              <el-button
                type="primary"
                v-if="!scope.row.info.member"
                plain
                @click="getUserInfo(scope)"
                >点击查询会员资产</el-button
              >
              <p v-if="scope.row.info.member">
                持卡：{{ scope.row.info.card.length }} 张
              </p>
              <p v-if="scope.row.info.member">
                余额：￥{{ scope.row.info.member.amount }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="last_sale_time" label="最近消费">
            <template slot-scope="scope">
              <!-- <p v-if="scope.row.info.member">
                累计消费额：￥{{ scope.row.info.sale_amount }}
              </p>
              <p v-if="scope.row.info.member">
                累计消费数：{{ scope.row.info.sale_times }} 次
              </p> -->
              <p>
                {{ scope.row.last_sale_time || "无消费记录" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="编辑" fixed="right">
            <template slot-scope="scope">
              <div class="btn-box">
                <!-- <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                > -->
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(2, scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  size="small"
                  @click="newRoute(scope.row)"
                  >开单</el-button
                >
                <!-- <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="delivery(scope)"
                  >分发</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="userIndex-right-cont-page">
          <el-pagination
            v-if="$pageStatus(total)[0]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 80, 100]"
            :page-size="$pageStatus(total)[1]"
            layout="total,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="type ? '新增资料' : '修改资料'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="userIndex-edit">
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>姓名：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>性别：</span>
            <el-select
              v-model="formLabelAlign.sex"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>手机：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.phone"
              clearable
            >
            </el-input>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>生日：</span>
            <el-date-picker
              style="width:100%"
              v-model="formLabelAlign.birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="userIndex-edit-list" style="display:block;">
          <div class="userIndex-edit-list-item">
            <span>会员等级：</span>
            <el-select
              v-model="formLabelAlign.category_id"
              filterable
              style="width: 100%;"
              placeholder="请选择..."
            >
              <el-option
                v-for="item in options1"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="userIndex-edit-list-item" style="margin-top:10px;">
            <span>推荐人：</span>
            <el-select
              v-model="formLabelAlign.p_user_id"
              style="width: 100%;"
              filterable
              placeholder="请输入关键字..."
            >
              <el-option
                v-for="item in options2"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>客户来源：</span>
            <el-select
              v-model="formLabelAlign.source_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userIndex-edit-list">
          <div class="userIndex-edit-list-item">
            <span>备注：</span>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="formLabelAlign.remark"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencels">取 消</el-button>
        <el-button v-if="type" type="primary" @click="saves(1)">添加</el-button>
        <el-button v-if="!type" type="primary" @click="saves(0)"
          >保存修改</el-button
        >
      </span>
    </el-dialog>

    <!-- 会员详情 -->
    <el-dialog
      title="会员详情"
      :visible.sync="dialogVisible2"
      width="100%"
      class="el-dialogs"
      :before-close="handleClose"
    >
      <userInfo
        :userData="userInfoData"
        :userOrder="userOrder"
        :userType="options1"
        :work="options2"
        :values="'0'"
        :key="new Date().getTime()"
      />
    </el-dialog>
    <!-- 会员详情 -->
    <!-- 会员流失等级设置 -->
    <el-dialog class="loss-form" title="会员流失预警等级设置" :visible.sync="dialogFormVisible" center @close="closeSettingLossLevelDialog">
      <el-form :model="lossForm">
        <div class="loss-form-item">
          <div>会员</div>
          <el-input v-model="lossForm.level1" size="small"></el-input>
          <div>天未到店消费，自动视为轻度流失客户。</div>
        </div>
        <div class="loss-form-item">
          <div>会员</div>
          <el-input v-model="lossForm.level2" size="small"></el-input>
          <div>天未到店消费，自动视为重度流失客户。</div>
        </div>
        <div class="loss-form-item">
          <div>会员</div>
          <el-input v-model="lossForm.level3" size="small"></el-input>
          <div>天未到店消费，自动视为已经流失客户。</div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="closeSettingLossLevelDialog">取消</el-button>
        <el-button type="danger" @click="handleSettingLossLevel">确认</el-button>
      </div>
    </el-dialog>
    <!-- 会员流失等级设置 -->
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import userInfo from "../../components/user/userInfo.vue";
export default {
  components: { userInfo },
  data() {
    return {
      msg: "",
      config: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "http://35.201.165.105:8000/controller.php",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的商品，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "./UEditor/",
        zIndex: 3500,
      },
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      dialogVisible1: false,
      dialogVisible2: false,
      userData: [
        {
          id: 0,
          icon: "",
          number: "0002",
          name: "刘晓明",
          type: "1",
          department: "1",
          price: "100",
          experience_price: "88",
          unified_price: "99",
          unified_price_type: "1",
          img: "",
          time: "100",
          wxapp: 1,
          wxstatus: 1,
          info: "",
          work_peopel: "1",
          status: "1",
        },
      ],
      userSearchData: [],
      userDataIndex: "",
      formLabelAlign: {},
      options: [
        {
          id: "0",
          name: "女",
        },
        {
          id: "1",
          name: "男",
        },
      ],
      options1: [],
      options2: [],
      options3: [], // 客户来源列表
      currentPage4: 1,
      pageSize: 1,
      total: 0,
      value: "0",
      input: "",
      radio: "",
      fromData: {
        id: "",
        name: "",
        all: false,
        list: [],
      },
      userInfoData: "", //会员详细信息
      userOrder: "", //会员订单数据
      dialogFormVisible: false,
      lossForm: { // 会员流失等级
        level1: '',
        level2: '',
        level3: ''
      },
      lossFormCopy: { // 会员流失等级（复制）
        level1: '',
        level2: '',
        level3: ''
      }
    };
  },
  methods: {
    /**
     * 设置会员流失等级
     */
    handleSettingLossLevel () {
      this.$axios({
        href: "/api/app/meiye/member/setls",
        data: {
          level1: this.lossForm.level1,
          level2: this.lossForm.level2,
          level3: this.lossForm.level3
        },
      }).then((res) => {
        console.log('settingLossLevelRes', res);
        if (res.data) {
          if (res.data.successful === 1) {
            this.$message({
              type: "success",
              message: "设置成功!",
            });
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        }
      });
    },
    /**
     * 点击'x'或者'取消'，关闭设置会员流失等级弹窗，并把用户输入的数据恢复成打开时那样（后端获取到的数据）
     */
    closeSettingLossLevelDialog() {
      this.dialogFormVisible = false;
      this.lossForm.level1 = this.lossFormCopy.level1;
      this.lossForm.level2 = this.lossFormCopy.level2;
      this.lossForm.level3 = this.lossFormCopy.level3;
    },
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    handleClose(done) {
      done();
    },
    searchs() {
      /* let val = this.input;
      if (!val) {
        this.$message({
          message: "关键字不能为空",
          type: "warning",
        });
        return false;
      } */
      this.currentPage4 = 1;
      this.getList();

      // let d = this.userData;
      // let len = d.length;
      // let arr = [];

      // for (let i = 0; i < len; i++) {
      //   if (d[i].name.includes(val)) {
      //     arr.push(d[i]);
      //   }
      // }
      // this.userSearchData = arr;
    },
    changes() {
      if (!this.input) {
        this.clears();
        return false;
      }
      this.searchs();
    },
    clears() {
      this.currentPage4 = 1;
      this.getList();
    },
    tabType(index) {
      this.tabIndex = index;
    },
    getworktype_name(id, obj) {
      let d = obj;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (d[i].id == id) {
          return d[i].name;
        }
      }
    },
    getList() {
      this.$axios({
        href: "/api/app/meiye/member/store",
        data: { page: this.currentPage4, keyword: this.input },
      }).then((res) => {
        console.log('storeRes', res);
        let len = res.data.items.length;
        for (let i = 0; i < len; i++) {
          res.data.items[i].status = res.data.items[i].status - 0 ? "1" : "0";
          res.data.items[i].info = {};
        }
        this.userData = res.data.items;
        this.userSearchData = res.data.items;
        this.total = res.data.total;
        if (res.data.member_ls) {
          this.lossForm.level1 = res.data.member_ls.level1;
          this.lossForm.level2 = res.data.member_ls.level2;
          this.lossForm.level3 = res.data.member_ls.level3;
          this.lossFormCopy.level1 = res.data.member_ls.level1;
          this.lossFormCopy.level2 = res.data.member_ls.level2;
          this.lossFormCopy.level3 = res.data.member_ls.level3;
        }
      });
    },
    getUserInfo(obj) {
      if (!obj.row.id) {
        return false;
      }
      this.$axios({
        href: "/api/app/meiye/member/index",
        data: { id: obj.row.id },
      }).then((res) => {
        this.userSearchData[obj.$index].info = res.data;
      });
    },
    getVipType() {
      this.$axios({
        href: "/api/app/meiye/membertype/store",
      }).then((res) => {
        //   console.log(res)
        this.options1 = res.data;
      });
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        this.options2 = res.data;
      });
    },
    updateList(obj) {
      this.$axios({
        href: "/api/app/meiye/member/edit",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.getList();
        this.dialogVisible = false;
      });
    },
    addList(obj) {
      this.$axios({
        href: "/api/app/meiye/member/add",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.getList();
        this.dialogVisible = !this.dialogVisible;
      });
    },
    delList(obj) {
      this.$axios({
        href: "/api/app/meiye/product/del",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getList();
      });
    },
    /* 添加更新 */
    edit(index, obj) {
      // console.log(obj);

      if (index == 2) {
        this.$axios({
          href: "/api/app/meiye/member/index",
          data: { id: obj.id },
        }).then((res) => {
          this.dialogVisible2 = true;
          let len = res.data.card.length;
          let len1 = res.data.treat.length;
          for (let i = 0; i < len; i++) {
            res.data.card[i].types = 1;
            // res.data.card[i].types = res.data.card[i].card.type;
          }
          for (let k = 0; k < len1; k++) {
            // res.data.treat[k].types = 1;
            res.data.treat[k].types = res.data.treat[k].type;
            res.data.treat[k].amount = res.data.treat[k].total_amount;
          }
          res.data.carData = res.data.card.concat(res.data.treat);

          this.userInfoData = res.data;
          console.log('memberData', res);
        });
        this.$axios({
          href: "/api/app/meiye/member/sale_store",
          data: { id: obj.id },
        }).then((res) => {
          this.userOrder = res.data;
          this.dialogVisible2 = true;
        });

        return false;
      }

      this.type = index;
      if (!index) {
        // this.userData.wxapp = this.userData.wxapp - 0 ? true : false;
        // this.userData.wxapp_wxstatus =
        //   this.userData.wxapp.wxstatus - 0 ? true : false;
        // this.userData.status = this.userData.status ? true : false;
        // this.unified_price_type = this.unified_price_type ? true : false;
        this.formLabelAlign = this.userSearchData[obj.$index];
        this.userDataIndex = obj.$index;
      }
      if (index) {
        this.formLabelAlign = {
          status: "1",
          picture: "",
          sort: 1,
        };
      }
      this.dialogVisible = !this.dialogVisible;
    },
    del(obj) {
      console.log(obj.row.status);
      // if (!(obj.row.status - 0)) {
      //   this.$message({
      //     message: "此商品已经下架了哦，不需要重复操作",
      //     type: "warning",
      //   });
      //   return false;
      // }

      if (obj.row.status - 0) {
        this.$confirm("此操作将下架该商品, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let d = obj.row;
            d.status = "0";
            this.updateList(d);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
        return false;
      }
      if (!(obj.row.status - 0)) {
        this.$confirm("此操作将上架该商品, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let d = obj.row;
            d.status = "1";
            this.updateList(d);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
        return false;
      }
    },
    delivery(obj) {
      console.log(obj);
      if (obj) {
        this.fromData = obj.row;
      } else {
        this.fromData = {};
      }
      this.dialogVisible1 = !this.dialogVisible1;
    },
    cencels() {
      this.dialogVisible = !this.dialogVisible;
      this.userDataIndex = "";
    },
    saves(obj) {
      let d = this.formLabelAlign;

      if (!d.name) {
        this.$message({
          message: "请输入用户名",
          type: "warning",
        });
        return false;
      }

      if (!d.sex) {
        this.$message({
          message: "请选择会员性别",
          type: "warning",
        });
        return false;
      }

      if (
        !/^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[\d])|(?:5[\d])|(?:6[\d])|(?:7[\d])|(?:8[\d])|(?:9[\d]))\d{8}$/.test(
          d.phone
        )
      ) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return false;
      }

      if (!d.category_id) {
        this.$message({
          message: "请选择会员类型",
          type: "warning",
        });
        return false;
      }
      if (!d.source_id) {
        this.$message({
          message: "请选择客户来源",
          type: "warning",
        });
        return false;
      }
      // console.log(d.birthday)

      /* 处理生日，不要年 */
      // if(d.birthday){
      //   let birthday = d.birthday.split('-');
      //   d.birthday = birthday[1] + '-' + birthday[2];
      // }

      if (obj) {
        // alert(1)
        this.addList(d);
        return false;
      }
      this.updateList(d);
      // this.$message({
      //   type: "success",
      //   message: "修改成功!",
      // });
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      let d = new FormData();
      d.append("image", file.file);
      d.append("token", sessionStorage.getItem("token"));
      this.$axios({
        href: "/api/app/meiye/upload/product",
        data: d,
      }).then((res) => {
        console.log(res, res.data.data.url);
        if (this.userDataIndex == "") {
          this.formLabelAlign.picture = res.data.data.url;
        } else {
          this.userSearchData[this.userDataIndex].picture = res.data.data.url;
        }
      });
    },
    /**
     * 获取客户来源列表
     */
    getCustomerSourceList() {
      this.$axios({
        href: "/api/app/meiye/membersource/store",
        loading: true,
      }).then((res) => {
        console.log('getCustomerSouceListRes', res);
        if (res.data.successful === 1) {
          this.options3 = res.data.data;
        }
      });
    },
    /* 开单 */
    newRoute(obj) {
      let _this = this;
      if (obj.name) {
        this.$axios({
          href: "/api/app/meiye/member/index",
          data: { id: obj.id },
          loading: true,
        }).then((res) => {
          let user = res.data.member;
          user.vip = [];
          res.data.name = res.data.member.name;
          // d.name = res.data.member.name;

          for (let i = 0; i < res.data.card.length; i++) {
            for (let k = 0; k < res.data.card[i].treats.length; k++) {
              res.data.card[i].treats[k].type = res.data.card[i].name;
              res.data.card[i].treats[k].vip = true;
              res.data.card[i].treats[k].project =
                res.data.card[i].treats[k].project_id;
              res.data.card[i].treats[k].treat_id =
                res.data.card[i].treats[k].id;
              user.vip.push(res.data.card[i].treats[k]);
            }
          }
          for (let l = 0; l < res.data.treat.length; l++) {
            res.data.treat[l].vip = true;
            res.data.treat[l].treat_id = res.data.treat[l].id;
            user.vip.push(res.data.treat[l]);
          }

          user.membertype = res.data.member.membertype;
          if (user.membertype) {
            user.membertype.product_disc = user.membertype.product_disc / 100;
            user.membertype.project_disc = user.membertype.project_disc / 100;
          } else {
            user.membertype = { id: 0, name: "大众会员" };
            user.membertype.product_disc = 1;
            user.membertype.project_disc = 1;
          }

          // user.membertype.product_disc =
          //   res.data.member.membertype.product_disc / 100;
          // user.membertype.project_disc =
          //   res.data.member.membertype.project_disc / 100;
          // console.log(user);
          _this.$store.commit("setUser", user);
          _this.$router.push({
            path: "/home",
            // query: { type: true },
          });
        });
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      console.log(file);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage4 = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getList();
    },
    distribute() {
      this.$message({
        type: "success",
        message: "分发成功!",
      });
      this.dialogVisible1 = !this.dialogVisible1;
    },
  },
  created() {
    this.getList();
    this.getVipType();
    this.getCustomerSourceList();
  },
};
</script>
<style lang="scss" scoped>
.userIndex {
  width: 100%;
  height: 100%;
  display: flex;
  &-photo {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-head {
        width: 100%;
        height: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        // background: red;
        &-list {
          // max-width: 33.33%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-list:nth-of-type(1) {
          width: 400px;
        }
        &-list:nth-of-type(2) {
          width: 339px;
          span {
            display: inline-block;
            width: 100px;
          }
          // margin-right: 50px;
        }
        &-list:nth-of-type(3) {
          // background: red;
          position: absolute;
          top: 0px;
          right: 10px;
          // width: 120px;
          button {
            width: 120px;
          }
        }
      }
      &-page {
        width: 100%;
        height: 60px;
        margin-top: 20px;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 500px;
    &-list {
      width: 100%;
      min-height: 40px;
      //   background: red;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 148px;
        height: 148px;
        margin-left: 15px;
      }
      &-item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // background: red;
        margin-right: 10px;
        span {
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 120px;
          // background: blue;
        }
      }
      &-item:last-child {
        margin-right: 0px;
      }
    }
  }
  .loss-form {
    /deep/ .el-dialog__header {
      padding: 20px;
      border-bottom: 1px solid #EFEFEF;
    }
    /deep/ .el-dialog__body {
      padding: 20px;
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 34px 0;
      .el-input {
        width: 103px;
        margin: 0 13px;
        /deep/ .el-input__inner {
          text-align: center;
        }
      }
    }
    /deep/ .el-button {
      width: 140px;
      height: 46px;
      box-sizing: border-box;
    }
  }
}
</style>

<style moudle>
.userIndex .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.userIndex .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.userIndex-right-cont-head {
  transition: all 0.5s;
}

.select {
  width: 80%;
}
.input {
  width: 76%;
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.edui-editor {
  z-index: 0 !important;
}
/* .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.avatar-uploader:hover {
  border: 1px dashed #409eff;
} */
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dialogs .el-dialog:first-child {
  width: 100%;
  min-height: 100%;
  max-height: auto;
  /* background: #cccccc; */
  color: #fff;
  margin: 0 !important;
}
.el-dialogs .el-dialog__body {
  padding: 0;
}
.input .el-input__inner {
  border-radius: 20px;
}
</style>
